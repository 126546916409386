




















































import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
import RegisterNewAccountCredentials from '@Auth/components/forms/register/RegisterNewAccountCredentials.vue'
import RegisterNewAccountDetails from '@Auth/components/forms/register/RegisterNewAccountDetails.vue'
import RegisterFormPreferences from '@Auth/components/forms/register/RegisterFormPreferences.vue'
import { Customer, ContactPreferenceType } from '@Core/@types/skyway'

@Component({
  components: {
    RegisterNewAccountCredentials,
    RegisterNewAccountDetails,
    RegisterFormPreferences,
  },
})
export default class RegisterForm extends Vue {
  @Prop({ type: Boolean, default: false }) credentialsSubmitted!: boolean
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Boolean, default: false }) isLoggedIn!: boolean
  @Prop({ type: Array })
  contactPreferenceTypes!: ContactPreferenceType[]
  @VModel({ type: Object }) localModel!: Customer & { primary_email: string }

  protected emailInputAllowed: boolean = false

  protected preferences = {}

  onEditEmail(): void {
    this.$router.push(this.$config.get('URLS').sign_in)
  }

  onSubmitCredentials() {
    this.$emit('auth:submit-credentials')
  }

  onSubmitRegistration() {
    this.$emit('registration:submitted')
  }

  mounted() {
    this.emailInputAllowed = Boolean(!this.localModel?.primary_email)
  }
}
