// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.register-details__heading{font-size:3.75rem;line-height:3rem;margin-bottom:3.5rem;color:#f7f6f3}.register-details h2{color:#000;margin-bottom:.5rem}.register-details .form-row-group{display:flex;justify-content:space-between}.register-details .form-row-group:not(:last-child){margin-bottom:1.5rem}.register-details .form-row-group .form-row{width:calc(50% - 4px);margin-bottom:0}.register-details .form-row{margin:0}.register-details .password-notification{margin-top:.5rem}.register-details__enter-address-manually{margin-bottom:.75rem;position:relative}.register-details__enter-address-manually button{font-weight:500;font-size:15.5px;color:#d4fe01;text-transform:uppercase;font-family:\"Ozik\"}.register-details__enter-address-manually svg{width:.75rem;height:.75rem;margin-top:.125rem}.register-details .register-details__phone{margin-bottom:2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
