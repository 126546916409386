




































































import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import MobilePageNav from '@UI/components/page-nav/MobilePageNav.vue'
import { CmsLink } from '@Core/@types/skyway'
import ArrowSvg from '~/static/images/icons/arrow.svg?inline'

/**
 * PageNav
 * - top of the Visit Page
 *
 * @param data - { show_nav: boolean }
 * @param position - the position of the nav
 * @param anchorLinks - array of links to render
 */

@Component({
  components: {
    MobilePageNav,
    ArrowSvg,
  },
})
export default class InPageNav extends Vue {
  @Prop({ type: String, default: '' }) header!: string
  @Prop({
    type: String,
    default: 'top',
    validator(value: string) {
      return ['top', 'side'].includes(value)
    },
  })
  position!: string

  @Prop({ type: Array, default: () => [] }) anchorLinks!: CmsLink[]

  @Prop({ type: Boolean, default: false }) showGetInTouch!: boolean

  protected observer: IntersectionObserver | null = null
  protected sections: HTMLElement[] | void = []
  protected activeLink: string | null = this.anchorLinks[0].url || null
  protected activeIndex = 0
  protected scrollDirection = null
  protected previousY = 0

  get linkList() {
    return (this.anchorLinks.length > 0 && this.anchorLinks) || []
  }

  isAnchorLink(url: string) {
    return url.includes('#')
  }

  scrollToSection(link: CmsLink, index?: number): void {
    if (!this.sections[this.activeIndex]) {
      this.createSections()
    }

    if (this.sections[this.activeIndex]) {
      this.observer && this.observer.unobserve(this.sections[this.activeIndex])
    }

    if (link.url && this.isAnchorLink(link.url)) {
      const id = link.url.substring(1) // using substring(1) because of the # in the link's url
      const section = document.getElementById(id)

      if (section) {
        section.scrollIntoView(true)

        if (index !== undefined) {
          this.activeIndex = index
        }
        this.previousY = 0
      }
    }
  }

  isActive(link: CmsLink): string | boolean {
    if (this.anchorLinks) {
      return this.activeLink === link.url && 'page-nav__anchor--active'
    }

    return link.url === this.$route.path && 'page-nav__anchor--active'
  }

  @Watch('activeIndex')
  onActiveIndexChange() {
    this.createObserver()
    this.activeLink =
      this.linkList[this.activeIndex] && this.linkList[this.activeIndex].url!
  }

  observerCallback(entries: IntersectionObserverEntry[], observer) {
    const entry = entries[0]

    if (entries[0] && this.sections) {
      const currentY = entry.boundingClientRect.y
      const bottom = entry.boundingClientRect.bottom
      const top = entry.boundingClientRect.top
      const link = this.anchorLinks[this.activeIndex]

      if (link && link.url) {
        if (this.previousY === 0) {
          this.previousY = currentY
        } else if (currentY < this.previousY) {
          // scroll down
          this.previousY = currentY
          if (bottom > 100) {
            // check to see if element is still visible
            this.previousY = currentY
          } else {
            // check to see if element has been scrolled past
            this.previousY = 0

            if (this.sections.length > this.activeIndex + 1) {
              observer.unobserve(this.sections[this.activeIndex]) // unobserve the current section and update the active index
              this.activeIndex = this.activeIndex + 1
            }
          }
        } else if (top < 100) {
          this.previousY = currentY
        } else {
          // scroll up
          this.previousY = 0

          if (this.activeIndex - 1 >= 0) {
            observer.unobserve(this.sections[this.activeIndex])
            this.activeIndex = this.activeIndex - 1
          }
        }
      }
    }
  }

  /**
   * Intersection observer to highlight sections on scroll
   */
  createObserver() {
    this.observer = new IntersectionObserver(this.observerCallback, {
      threshold: [0, 0.25, 0.5, 0.75, 1],
    })

    if (this.observer && this.sections[this.activeIndex]) {
      this.observer.observe(this.sections[this.activeIndex])
    }
  }

  createSections(): void {
    if (this.anchorLinks.length > 0) {
      const sections: HTMLElement[] = []

      this.sections = this.anchorLinks.forEach((link) => {
        if (link.title) {
          const anchorId = encodeURIComponent(link.title).toLocaleLowerCase()
          const section = document.getElementById(anchorId)

          if (section && anchorId && !sections.includes(section)) {
            sections.push(section)
          }
        }
      })

      this.sections = sections
    }
  }

  /**
   * @todo - SM - 30/09/22
   *
   * The scrollspy effect does not work because the sections are LazyHydrated
   * We'll need to rethink this
   */
  mounted() {
    this.createSections()

    this.$nextTick(() => {
      if (this.sections) {
        this.createObserver()
      }
    })
  }

  beforeDestroy(): void {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
