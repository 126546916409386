var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"provider",staticClass:"form-row field field--text field--file-upload",attrs:{"rules":"required|image|size:4000","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"upload"},[_c('label',{staticClass:"ui-textarea__label",class:[
        {
          'ui-textinput__label--optional':
            !_vm.compiledRules.includes('required'),
        } ],attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.labelValue(_vm.label))+"\n      "),(!_vm.compiledRules.includes('required'))?_c('span',{staticClass:"optional"},[_vm._v("optional\n      ")]):_vm._e()]),_vm._v(" "),(_vm.description)?_c('span',{staticClass:"field__description"},[_vm._v("\n      "+_vm._s(_vm.descriptionValue(_vm.description))+"\n    ")]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"input",staticClass:"input-file",attrs:{"id":_vm.id,"type":"file","name":_vm.label,"disabled":_vm.isSaving,"accept":"image/*"},on:{"change":_vm.onFileChange}}),_vm._v(" "),(!_vm.isSuccess)?_c('div',{staticClass:"flex upload-actions"},[(_vm.isInitial || _vm.isSaving)?_c('button',{staticClass:"button button--form-field w-100",attrs:{"disabled":_vm.isSaving},on:{"click":function($event){$event.preventDefault();return _vm.triggerFileInput.apply(null, arguments)}}},[_c('UploadSvg'),_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")],1):(_vm.isError)?_c('button',{staticClass:"button button--form-field w-100",on:{"click":function($event){$event.preventDefault();_vm.status = 'INITIAL'}}},[_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text text--small"},[_vm._v("\n        "+_vm._s(_vm.isError
            ? 'There was an error uploading the file, please check and try again'
            : 'Accepted formats: PNG, JPG, JPEG. Max size 4mb')+"\n      ")])]):(_vm.isSuccess)?_c('div',[_c('p',[_vm._v("Thank you, your file has been attached successfully")])]):_vm._e(),_vm._v(" "),(errors && errors[0])?_c('div',{staticClass:"form-errors form-errors--upload"},[_c('p',[_vm._v(_vm._s(errors[0]))])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }