












































import { Component, mixins } from 'nuxt-property-decorator'
import AbstractField from '@Forms/components/fields/AbstractField.vue'
import { stripTags } from '@Forms/helpers/stripTags.js'

@Component
export default class TextareaField extends mixins<AbstractField>(
  AbstractField
) {
  labelValue(label: string) {
    return stripTags(label)
  }
  descriptionValue(description: string) {
    return stripTags(description)
  }
}
