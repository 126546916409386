





















































import { Component, mixins } from 'nuxt-property-decorator'
import Page from '@Marketing/pages/_.vue'
import FormContainer from '@Forms/components/FormContainer.vue'

@Component({
  components: { FormContainer },
})
export default class VenueHirePage extends mixins<Page>(Page) {
  protected venueHireFormId: number = 1
}
