import {
  Customer,
  CustomerAddress,
  CustomerContactPreference,
} from '@Core/@types/skyway'
import Model from './Model'

export default class CustomerModel extends Model implements Customer {
  first_name?: string = ''
  last_name?: string = ''
  primary_email?: string = ''
  prefix?: string = ''
  phone?: string = ''
  username?: string = ''
  password?: string = ''
  password_confirm?: string = ''
  address?: CustomerAddress = {
    country: null,
    country_ref: null,
    street1: '',
    street2: '',
    state_ref: null,
    city: '',
    postal_code: '',
    type_ref: 3,
  }
  preferences?: CustomerContactPreference[] = []

  constructor(values?: CustomerModel) {
    super(values)
  }
}
