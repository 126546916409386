





































































































import { Component, Vue, Prop, Watch, namespace } from 'nuxt-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { sumBy } from 'lodash'
import SigninContainer from '@Auth/components/containers/SigninContainer.vue'
import RegisterContainer from '@Auth/components/containers/RegisterContainer.vue'
import FormFields from '@Forms/components/FormFields.vue'
import Loader from '@UI/components/loader/Loader.vue'
import FormSuccess from '@Forms/components/FormSuccess.vue'

const customer = namespace('customer')
const basket = namespace('basket')
const forms = namespace('forms')

interface Submission {
  instance_ref
  event_ref
  customer_ref
}

@Component({
  components: {
    FormFields,
    ValidationObserver,
    RegisterContainer,
    SigninContainer,
    Loader,
    FormSuccess,
  },
})
export default class FormContainer extends Vue {
  @Prop({ required: true }) id!: number | string
  @Prop({ type: String }) eventRef?: string
  @Prop({ type: String }) instanceRef?: string
  @Prop({ type: String }) itemRef?: string
  @Prop({ type: Boolean, default: false }) showTitle?: boolean
  @Prop({ type: String, default: 'Submit' }) buttonLabel?
  @Prop({ type: Object }) successMessage

  // should this component deal with the submission,
  // or is it passed back up the chain once submitted?
  @Prop({ type: Boolean, default: false }) externalSubmit?

  // arbitrary object to pass through UI config, needs more thought
  @Prop({
    type: Object,
    default: () => {
      checkboxBoxed: false
    },
  })
  config!: any

  @customer.State
  customer

  @customer.Getter
  isLoggedIn

  @customer.Action
  getActiveCustomer

  @basket.Action
  getBasket

  @basket.Getter
  tickets

  @forms.Getter
  submissionsByInstance

  @forms.State
  submissions

  @forms.State
  form

  @forms.Action
  getForm

  @basket.Getter
  lastAddedItemRef

  protected model: Submission = {
    instance_ref: null,
    customer_ref: null,
    event_ref: null,
  }
  protected loading: boolean = false
  protected submitting: boolean = false
  protected submitted: boolean = false
  protected hasErrors: boolean = false
  protected showRegistration: boolean = false
  protected ticketNumber: number = 1
  protected observer: Vue | Element | Vue[] | Element[] | null = null

  @Watch('isLoggedIn')
  registerValidator(newVal) {
    this.$nextTick(() => {
      this.observer = this.$refs.validator
    })
  }

  get ticketsByInstance(): number {
    return this.instanceRef
      ? sumBy(
          this.tickets.filter(
            (c) => parseInt(c.extra.instance_ref) === parseInt(this.instanceRef)
          ),
          'qty'
        )
      : 0
  }

  get submissionsByThisInstance(): number {
    return this.instanceRef && this.submissionsByInstance[this.instanceRef]
      ? this.submissionsByInstance[this.instanceRef].length
      : 0
  }

  get hasFields() {
    if (!this.loading) {
      return this.form.fields && this.form.fields.length > 0
    } else {
      return false
    }
  }

  get validationErrors() {
    let errors = false
    if (this.$refs.validator && this.$refs.validator.errors) {
      Object.values(this.$refs.validator.errors).forEach((v) => {
        if (v.length) {
          errors = true
        }
      })
    }
    return errors
  }

  get existingSubmission() {
    return this.submissions.find(
      (s) =>
        Number(s.id) === Number(this.form.id) &&
        Number(s.basket_item_ref) === Number(this.itemRef) &&
        s.ticket_number === this.ticketNumber
    )
  }

  checkEmailExists(result) {
    if (!result) {
      this.showRegistration = true
    }
  }

  get customerEmail() {
    if (this.isLoggedIn) {
      const customerEmails = this.customer.emails
      const primaryEmail = customerEmails.filter(
        (email) => email.primary === true
      )
      return primaryEmail[0].email
    }
    return false
  }

  scrollToFirstInvalid(): void {
    const invalid = this.$el.querySelectorAll('.validated.invalid')

    if (invalid && invalid.length > 0) {
      const first = invalid[0]

      if (first) {
        first.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  /**
   * Manually calling the observer validate() method here
   * rather than using handleSubmit() allows us more
   * control over the UI
   */
  async submitForm(): Promise<void> {
    if (!this.observer) {
      this.observer = this.$refs.validator
    }
    // @ts-ignore
    const isValid = this.observer && (await this.observer.validate())
    if (!isValid) {
      this.$eventBus.notifyFailure(
        'There are one or more errors with your submission, please check and try again.'
      )

      this.scrollToFirstInvalid()
    } else {
      this.submitting = true

      const shouldDefer = this.form.defer

      // if form is to be defered it is stored in local storage until it is ready to be processed (i.e. when the order is complete)
      let result
      if (shouldDefer) {
        const submissionData = this.formSubmissionData(this.model)
        if (isNaN(submissionData.basket_item_ref)) {
          this.$eventBus.notifyFailure(
            "Sorry, there was an error submitting this form. We couldn't identify a corresponding item in your cart."
          )
        } else {
          result = await this.$store.dispatch(
            'forms/deferForm',
            this.formSubmissionData(this.model)
          )
        }
      } else {
        result = await this.$store.dispatch(
          'forms/submitForm',
          this.formSubmissionData(this.model)
        )
      }

      if (
        (result && result.result && result.result.errors.length > 0) ||
        !result
      ) {
        this.hasErrors = true
      }

      if (!this.hasErrors && this.form.one_per_ticket) {
        if (this.ticketsByInstance > this.ticketNumber) {
          this.progressToNextRecipient()
          return
        }
      }

      if (!this.hasErrors) {
        // pass back up the chain to handle next steps
        if (this.externalSubmit) {
          this.$emit('submit', result)

          // forward on to different URL
        } else if (!this.hasErrors && (this.form.submit_url || shouldDefer)) {
          let submit_url =
            shouldDefer && !this.form.submit_url
              ? this.$config.get('URLS').basket
              : this.form.submit_url

          if (submit_url.substr(-1) !== '/') submit_url = `${submit_url}/`

          this.$router.push(submit_url)
        } else {
          this.submitted = true
          this.$emit('submitted', true)
        }
      } else {
        this.submitting = false
      }
    }
  }

  progressToNextRecipient() {
    this.ticketNumber++
    this.submitted = true
    this.submitting = false

    this.resetModel()
    this.hydrateForm()

    window.scrollTo(0, 0)
  }

  formSubmissionData(data): FormSubmission {
    const shouldDefer = this.form.defer || !this.isLoggedIn
    return {
      id: this.id,
      title: this.form.title,
      ticket_number: this.ticketNumber,
      basket_item_ref: shouldDefer ? this.lastAddedItemRef : undefined,
      data,
    }
  }

  resetForm() {
    this.submitted = false
    this.hasErrors = false
    this.submitting = false
  }

  defaultModel(fieldType: string) {
    const map = {
      checkboxGroup: [],
      checkbox: false,
    }

    return map[fieldType] || null
  }

  resetModel() {
    this.model = {
      instance_ref: this.instanceRef ? parseInt(this.instanceRef) : null,
      event_ref: this.eventRef ? parseInt(this.eventRef) : null,
      customer_ref:
        this.customer && this.customer.customer_ref
          ? parseInt(this.customer.customer_ref)
          : null,
    }
    for (const field of this.form.fields) {
      if (
        field.name &&
        field.type &&
        !['header', 'paragraph'].includes(field.type)
      ) {
        Vue.set(this.model, field.name, this.defaultModel(field.type))
      }
    }
  }

  hydrateForm() {
    if (this.existingSubmission) {
      for (const key in this.existingSubmission.data) {
        Vue.set(this.model, key, this.existingSubmission.data[key])
      }
    }
  }

  async mounted() {
    await this.getForm(this.id)

    if (
      (this.form && this.form.requires_login) ||
      !this.customer ||
      this.customer.customer_ref === undefined
    ) {
      await this.getActiveCustomer()
    }
    await this.$store.dispatch('session/setRedirect', this.$route.path)

    this.resetModel()
    this.hydrateForm()
    this.getBasket({
      fetchPolicy: 'network-only',
    })
    this.$nextTick(() => {
      this.observer = this.$refs.validator
    })

    // if this is a form for tickets and
    // if we already have enough submissions for this instance, then forward on to the basket
    if (
      this.ticketsByInstance > 0 &&
      this.submissionsByThisInstance >= this.ticketsByInstance
    ) {
      this.$router.push(this.$config.get('URLS').basket)
    }
  }
}
