var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-row field field--text mb-4"},[_c('ValidationProvider',{staticClass:"ui-textarea",attrs:{"name":_vm.label.toLowerCase(),"rules":_vm.compiledRules,"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
return [_c('label',{staticClass:"field__label",class:[
        {
          'field__label--optional': !_vm.compiledRules.includes('required'),
        } ],attrs:{"for":_vm.id}},[_vm._v("\n      "+_vm._s(_vm.labelValue(_vm.label))+"\n      "),(!_vm.compiledRules.includes('required'))?_c('span',{staticClass:"optional"},[_vm._v("optional")]):_vm._e()]),_vm._v(" "),(_vm.description)?_c('span',{staticClass:"field__description"},[_vm._v("\n      "+_vm._s(_vm.descriptionValue(_vm.description))+"\n    ")]):_vm._e(),_vm._v(" "),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"field__input",class:classes,attrs:{"id":_vm.id,"name":_vm.id,"type":_vm.fieldType,"placeholder":_vm.placeholder,"min":_vm.min},domProps:{"value":(_vm.localValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}},'textarea',ariaInput,false)),_vm._v(" "),(errors && errors[0])?_c('span',_vm._b({staticClass:"form-errors"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }