// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.form-success{display:flex;flex-direction:column;align-items:center;margin-top:2rem}@media only screen and (min-width:768px){.form-success{margin-top:7rem}}.form-success__content-wrapper{display:flex;flex-direction:column;align-items:center;text-align:center}.form-success__icon-wrapper{display:flex;justify-content:center;align-items:center;border-radius:50%;background:#d4fe01;height:3.25rem;width:3.25rem;margin-bottom:2rem}.form-success__icon{width:1.5rem;height:1.1375rem;color:#000}.form-success__header{text-transform:uppercase;color:#f7f6f3}.form-success__confirmation{margin:0 0 2rem}.form-success__email{text-decoration:underline;font-weight:600}.form-success__bubble{background:rgba(66,66,66,.6);border-radius:6.25rem;margin:0;padding:.5rem 1rem;color:#f7f6f3}.form-success__button{margin-top:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
