




























































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import AbstractField from '@Forms/components/fields/AbstractField.vue'
import { stripTags } from '@Forms/helpers/stripTags.js'
import RadioboxMarked from '@icons/material/svg/radiobox-marked.svg?inline'
import RadioboxBlank from '@icons/material/svg/radiobox-blank.svg?inline'

@Component({
  components: {
    RadioboxMarked,
    RadioboxBlank,
  },
})
export default class RadioField extends mixins(AbstractField) {
  @Prop({ type: Array, required: true }) choices!: Object

  labelValue(label: string) {
    return stripTags(label)
  }

  descriptionValue(description: string) {
    return stripTags(description)
  }
}
