






































import { Component, Vue, Prop, VModel } from 'nuxt-property-decorator'
import TextField from '@Forms/components/fields/TextField.vue'
import TextareaField from '@Forms/components/fields/TextareaField.vue'
import ParagraphField from '@Forms/components/fields/ParagraphField.vue'
import RadioField from '@Forms/components/fields/RadioField.vue'
import CheckboxField from '@Forms/components/fields/CheckboxField.vue'
import CheckboxGroupField from '@Forms/components/fields/CheckboxGroupField.vue'
import DropdownField from '@Forms/components/fields/DropdownField.vue'
import HeaderField from '@Forms/components/fields/HeaderField.vue'
import DateOfBirthField from '@Forms/components/fields/DateOfBirthField.vue'
import FileUploadField from '@Forms/components/fields/FileUploadField.vue'

@Component({
  components: {
    TextField,
    TextareaField,
    ParagraphField,
    RadioField,
    CheckboxField,
    DropdownField,
    HeaderField,
    DateOfBirthField,
    FileUploadField,
    CheckboxGroupField,
  },
})
export default class FormFields extends Vue {
  @Prop(Array) fields!: [Field]
  @Prop({ type: Object }) value!: object

  @Prop({ type: String, default: 'Submit' }) buttonLabel!: string
  @Prop({ type: Boolean, default: false }) hideSubmit!: boolean

  // arbitrary object to pass through UI config, needs more thought
  @Prop({
    type: Object,
    default: () => {
      checkboxBoxed: false
    },
  })
  config!: any

  loading = false
  model = {}

  //protected localModel = []
  @VModel({}) localModel!: string[]

  fieldType(field) {
    switch (field.type) {
      default:
        return `${this.fieldComponentName(field.type)}Field`
    }
  }

  fieldComponentName(name) {
    const words = name.split('_')
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    }
    return words.join('')
  }

  // get localModel() {
  //   return this.value
  // }

  // set localModel(localValue) {
  //   this.$emit('input', localValue)
  // }

  // update(key, value) {
  //   this.$emit('input', { ...this.localModel, [key]: value })
  // }

  submitForm() {
    this.$emit('submit')
  }
}
