












































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import RegisterNewAccountCredentials from '@Auth/components/forms/register/RegisterNewAccountCredentials.vue'
import RegisterFormPreferences from '@Auth/components/forms/register/RegisterFormPreferences.vue'
import ReadOnlyEditable from '@UI/components/forms/fields/ReadOnlyEditable.vue'
import {
  ContactPreferenceType,
  CustomerContactPreferenceInput,
  ActivateAccountInput,
} from '@Core/@types/skyway'

export type CustomerToActivate = ActivateAccountInput & {
  first_name: string
  preferences: CustomerContactPreferenceInput[] | []
}

@Component({
  components: {
    RegisterNewAccountCredentials,
    RegisterFormPreferences,
    ReadOnlyEditable,
  },
})
export default class SigninActivateAccount extends Vue {
  @Prop() customer_ref?: string
  @Prop({ default: '' }) email!: string
  @Prop({ type: Array })
  contactPreferenceTypes!: ContactPreferenceType[]

  public customer: CustomerToActivate = {
    customer_ref: this.customer_ref,
    first_name: '',
    last_name: '',
    credentials: {
      username: this.email || '',
      password: '',
    },
    preferences: [],
  }

  onEditEmail(): void {
    this.$emit('auth:edit-email')
  }

  onSubmit() {
    this.$emit('input', this.customer)
    this.$emit('auth:submit-activation', this.customer)
  }
}
