

























import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator'
import CustomerModel from '@Core/models/Customer'
import { mockAddress } from '@Account/api/models/customer'
import {
  ContactPreferenceCategory,
  ContactPreferenceType,
  CreateCustomerInput,
  Customer,
} from '@Core/@types/skyway'
import RegisterForm from '@Auth/components/forms/register/RegisterForm.vue'
import FormContainer from '@Forms/components/FormContainer.vue'
import { CreateCustomerTransformer } from '@Account/api/transformers/customer'

const customer = namespace('customer')

@Component({
  components: { RegisterForm, FormContainer },
})
export default class RegisterContainer extends Vue {
  @Prop({ type: Function }) onRegisterSuccess?: () => Promise<any>
  @Prop({ type: Function }) onBeforeRegister?: (
    context: Vue
  ) => Promise<boolean>
  @Prop({ type: Boolean, default: true }) redirectOnSuccess?: boolean

  @customer.State
  protected customer!: Customer

  @customer.State
  protected temporary_email!: string

  @customer.Action
  protected getContactPreferenceCategories!: () => Promise<
    ContactPreferenceCategory[]
  >

  @customer.Getter
  protected contactPreferenceTypes!: ContactPreferenceType[]

  @customer.Action
  protected checkEmailExists!: (email: string) => Promise<number>

  @customer.Getter
  protected isLoggedIn!: boolean

  /**
   * The customer model
   */
  public customerModel: CustomerModel | null = null

  public loading: boolean = false

  /**
   * Toggle state once valid email/password entered
   */
  public credentialsSubmitted: boolean = false

  /**
   * Build the correct format for sending to the skyway service
   *
   * @return CreateCustomerInput
   */
  get createCustomerInput(): CreateCustomerInput {
    return CreateCustomerTransformer(this.customerModel)
  }

  get emailPreferenceTypes() {
    return this.contactPreferenceTypes.filter((type) => type.name === 'Email')
  }

  /**
   * We pass a function to update the model
   * through to components using the slot
   */
  onUpdateModel(event, prop) {
    this[prop] = event.target.value
  }

  onEditEmail(): void {
    this.$router.push(this.$config.get('URLS').sign_in)
  }

  /**
   * Handle registration of new customer and redirect to the account page
   *
   * @return void
   */
  async onSubmitRegistration(): Promise<void> {
    if (typeof this.onBeforeRegister === 'function') {
      const proceed = await this.onBeforeRegister(this)

      if (!proceed) {
        return
      }
    }

    this.loading = true

    this.$store.commit('customer/CLEAR_TEMPORARY_EMAIL')

    try {
      const result = await this.$store.dispatch('customer/createCustomer', {
        input: this.createCustomerInput,
        recaptcha: await this.$recaptcha.execute('register'),
      })
      const login = await this.$store.dispatch('customer/processLogin', result)

      if (login && result) {
        this.handleRegisterSuccess(result)
      } else {
        this.loading = false
        this.$eventBus.notifyFailure(
          'There was an error while processing your registration. Please try again.'
        )
      }
    } catch (err_) {
      this.loading = false
      this.$eventBus.notifyFailure(
        'There was an error while processing your registration. Please try again.'
      )
      this.$logger.captureException(err_, {
        tags: { registration: 'Registration Failed' },
        extra: { data: this.customerModel },
      })
    }
  }

  protected async handleRegisterSuccess(result: boolean) {
    if (typeof this.onRegisterSuccess === 'function') {
      await this.onRegisterSuccess()
    }

    this.$emit('registered', result)
    this.$gtmEvents.signUp('Email')

    if (this.redirectOnSuccess) {
      let redirect = await this.$store.dispatch('session/getRedirect')

      if (redirect === this.$config.get('URLS').sign_in) {
        redirect = this.$config.get('URLS').account
      }

      await this.$store.dispatch(
        'session/setRedirect',
        this.$config.get('URLS').account
      )

      this.$router.push(redirect)
    }
  }

  protected onInvalidAddress() {
    this.$eventBus.notifyFailure(
      'Please add your address details to complete your registration.'
    )
    const loqateField = document.querySelector(
      '.field--loqate .multiselect'
    ) as HTMLElement

    if (loqateField) {
      loqateField.focus()
    }
  }

  /**
   * The auth:submit-credentials event will only fire if client
   * side validation passes, so we can be reasonably sure the
   * data is good
   */
  async onSubmitCredentials(): Promise<void> {
    window.scrollTo(0, 0)
    const handleEmailExists = async () => {
      await this.$router.replace(this.$config.get('URLS').sign_in)
      this.$eventBus.notifyFailure(
        'We already have an account on record using that email address. Please login or reset your password.'
      )
    }
    if (!this.temporary_email) {
      try {
        const checkEmailExists: number = await this.checkEmailExists(
          this.customerModel?.primary_email as string
        )

        if (checkEmailExists !== 0) {
          handleEmailExists()
          return
        }
      } catch (err) {
        handleEmailExists()
        return
      }
    }
    this.credentialsSubmitted = true
  }

  async submitAdditionalRegistrationForm(): Promise<void> {
    const redirect = await this.$store.dispatch('session/getRedirect')
    this.$router.push(redirect)
  }

  /**
   * Grab email from store when component is initialised
   * this will have been set by the customer attempting to login
   * using an email not present on the system
   */
  created(): void {
    this.customerModel = new CustomerModel()
    this.customerModel.primary_email = this.temporary_email
    this.customerModel.address = { ...mockAddress, postal_code: '' }
  }

  async mounted() {
    try {
      if (this.contactPreferenceTypes.length < 1) {
        await this.getContactPreferenceCategories()
      }
      await this.$recaptcha.init()
      if (this.$recaptcha.options) {
        this.$recaptcha.options.hideBadge = false
      }
    } catch (err_) {
      this.$logger.captureException(err_, {
        tags: { recaptcha: `[Init failed]` },
      })
    }
  }

  beforeDestroy() {
    try {
      this.$recaptcha.destroy()
    } catch (err_) {
      this.$logger.captureException(err_, {
        tags: {
          recaptcha: '[Recaptcha destroy exception caught on registration]',
        },
      })
    }
  }
}
