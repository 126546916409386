















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ButtonLink from '@UI/components/links/ButtonLink.vue'
import Back from '@UI/components/buttons/Back.vue'

@Component({
  components: { ButtonLink, Back },
})
export default class SignInPassword extends Vue {
  @Prop({ type: String }) email!: string
  @Prop() name!: string

  public password: string = ''

  protected button = {
    title: 'Send password reset email',
    url: this.$config.get('URLS').forgotten_password,
  }

  get welcome() {
    return 'Welcome back' + (this.name ? `, ${this.name}!` : '')
  }

  onEditEmail() {
    this.$emit('auth:edit-email')
  }

  onSubmitPassword(): void {
    if (this.password === '' || !this.password) {
      this.$eventBus.notifyFailure('The password field cannot be blank')
      return
    }
    this.$emit('auth:submit-password', this.password)
  }

  onClickChangeEmail(email): void {
    this.$emit('auth:change-email', email)
  }

  backToEmailSignIn() {
    this.$emit('auth:edit-email')
  }
}
