








































































import { Component, mixins } from 'nuxt-property-decorator'
import AbstractField from '@Forms/components/fields/AbstractField.vue'
import { stripTags } from '@Forms/helpers/stripTags.js'
// @ts-ignore:next-line
import UploadSvg from '~/static/images/svgs/upload-arrow.svg?inline'

@Component({ components: { UploadSvg } })
export default class FileUploadField extends mixins<AbstractField>(
  AbstractField
) {
  protected status: 'INITIAL' | 'SAVING' | 'SUCCESS' | 'ERROR' = 'INITIAL'

  get isInitial() {
    return this.status === 'INITIAL'
  }
  get isSaving() {
    return this.status === 'SAVING'
  }
  get isError() {
    return this.status === 'ERROR'
  }
  get isSuccess() {
    return this.status === 'SUCCESS'
  }

  get buttonText() {
    const map = {
      INITIAL: 'Upload',
      SAVING: 'Attaching File',
      ERROR: 'Reset',
      SUCCESS: '',
    }

    return map[this.status]
  }

  /**
   * Send the file through the RT service
   * see @Forms/services/file-service
   */
  async save(formData): Promise<void> {
    try {
      this.status = 'SAVING'
      const result = await this.$upload.saveFile(formData)

      if (result) {
        this.status = 'SUCCESS'

        this.localValue = result
      } else {
        this.status = 'ERROR'
      }
    } catch (err_) {
      this.status = 'ERROR'

      this.$logger.captureException(err_, {
        tags: {
          upload: 'File Upload Failed',
        },
      })
    }
  }

  /**
   * @todo - do we need further file validation here
   * or is it safely handled by the BE
   */
  async onFileChange(e) {
    const { valid } =
      this.$refs.provider && (await this.$refs.provider.validate(e))

    if (valid && e.target && e.target.files && e.target.files[0]) {
      this.save(e.target.files[0])
    }
  }

  triggerFileInput() {
    const input = this.$refs.input as HTMLInputElement

    if (input) {
      input.click()
    }
  }
  labelValue(label: string) {
    return stripTags(label)
  }

  descriptionValue(description: string) {
    return stripTags(description)
  }
}
