






































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Back from '@UI/components/buttons/Back.vue'

/**
 * Sign
 */
@Component({ components: { Back } })
export default class SigninEmail extends Vue {
  @Prop({ type: String, default: '' }) temporaryEmail!: string

  get email(): string {
    return this.temporaryEmail
  }

  set email(email) {
    this.$store.commit('customer/SET_TEMPORARY_EMAIL', email)
  }

  onSubmitEmail() {
    this.$emit('auth:submit-email', this.email)
  }

  /**
   * Capture tab off of email field - if tab is pressed presumably user is ready to move to next step
   * Slight delay to give the field time to validate
   * @param {event} e
   */
  detectKeypress(e) {
    if (e.key == 'Tab') {
      setTimeout(() => {
        this.onSubmitEmail()
      }, 100)
    }
  }

  mounted() {
    this.email = this.temporaryEmail
  }

  // Google sign in
  // TODO: implement
  signInWithGoogle() {
    alert('sign in with google')
  }
}
