






































import { Component, Prop, Vue, VModel } from 'nuxt-property-decorator'
import PostcodeOnlyForm from '@UI/components/addresses/PostcodeOnlyForm.vue'
import ArrowRightThickSvg from '@icons/material/svg/arrow-right-thick.svg?inline'

@Component({
  components: {
    PostcodeOnlyForm,
    ArrowRightThickSvg,
  },
})
export default class RegisterNewAccountDetails extends Vue {
  @VModel({ type: Object }) localModel
  @Prop({ type: Boolean, default: true }) showTitle!: boolean

  public manualAddress: boolean = false
  public lookupCountry?: string | null = null

  onClickChangeEmail(email): void {
    this.$emit('auth:change-email', email)
  }
}
