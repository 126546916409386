




















































































import { Component, mixins, Prop } from 'nuxt-property-decorator'
import AbstractField from '@Forms/components/fields/AbstractField.vue'
import { stripTags } from '@Forms/helpers/stripTags.js'
import { extend } from 'vee-validate'

@Component
export default class DateOfBirthField extends mixins(AbstractField) {
  @Prop(String) wrapperClass?: string
  @Prop({ type: Boolean, default: true }) validateInline!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  get minDate() {
    if (this.min) {
      return this.$moment()
        .set('year', this.$moment().get('year') - Number(this.min))
        .format('YYYY-MM-DD')
    } else {
      return this.$moment().format('YYYY-MM-DD')
    }
  }

  get localDOBValue() {
    let parts = ['', '', '']

    if (typeof this.localValue === 'string') {
      parts = this.localValue.split('-')
    }

    return {
      year: parts[0],
      month: parts[1],
      day: parts[2],
    }
  }

  labelValue(label: string) {
    return stripTags(label)
  }

  descriptionValue(description: string) {
    return stripTags(description)
  }

  updateLocalDOBValue() {
    const { year, month, day } = this.localDOBValue

    if (year && month && day) {
      this.$emit('input', `${year}-${month}-${day}`)
    }
  }

  mounted() {
    extend('before', {
      params: ['limit'],
      validate: (value, { limit }) => {
        limit = this.$moment(this.minDate, 'YYYY-MM-DD')
        value = this.$moment(value, 'YYYY-MM-DD')
        return value.isBefore(limit)
      },
      message: (fieldName) => {
        return `You must be older than ${this.min}`
      },
    })
  }
}
