












































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Form } from '@Core/@types/skyway'
import Check from '~/static/images/svgs/check.svg?inline'

@Component({
  components: { Check },
})
export default class FormSuccess extends Vue {
  @Prop({ type: Object, required: true }) form!: Form
  @Prop() customerEmail?: string
  @Prop({ type: Object }) successMessage

  handleClick() {
    if (this.successMessage.button_link) {
      this.$router.push(this.successMessage.button_link)
    }
  }
}
