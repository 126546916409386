var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-selectinput"},[(_vm.label)?_c('label',{staticClass:"field__label",class:[
      'field__label--has-desc',
      {
        'field__label--optional': !_vm.compiledRules.includes('required'),
      } ]},[_vm._t("label",function(){return [_vm._v("\n      "+_vm._s(_vm.labelValue(_vm.label))+"\n      "),(!_vm.compiledRules.includes('required'))?_c('span',{staticClass:"optional"},[_vm._v("optional")]):_vm._e()]},{"for":_vm.id})],2):_vm._e(),_vm._v(" "),(_vm.description)?_c('span',{staticClass:"field__description"},[_vm._v("\n    "+_vm._s(_vm.descriptionValue(_vm.description))+"\n  ")]):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"ui-selectinput__wrapper",attrs:{"name":_vm.label.toLowerCase(),"rules":_vm.isRequired,"mode":"eager","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var ariaInput = ref.ariaInput;
    var ariaMsg = ref.ariaMsg;
return [_c('select',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"field__input",attrs:{"id":_vm.id,"name":_vm.id},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.localValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},'select',ariaInput,false),[_c('option',{attrs:{"disabled":""}},[_vm._v(_vm._s(_vm.placeholder))]),_vm._v(" "),_vm._l((_vm.choices),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v("\n        "+_vm._s(option.label)+"\n      ")])})],2),_vm._v(" "),(_vm.descriptiveLabel)?_c('span',{staticClass:"ui-textinput__description"},[_vm._v(_vm._s(_vm.descriptiveLabel))]):_vm._e(),_vm._v(" "),(errors[0])?_c('span',_vm._b({staticClass:"form-errors"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }