
























































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import AbstractField from '@Forms/components/fields/AbstractField.vue'
import { stripTags } from '@Forms/helpers/stripTags.js'

export interface SelectOption {
  label: string
  value: any
}

@Component
export default class DropdownField extends mixins(AbstractField) {
  @Prop({ type: Array, required: true }) choices!: SelectOption
  @Prop({ type: String }) descriptiveLabel?: string
  @Prop({ type: Boolean, required: false, default: false }) required?: Boolean

  get isRequired() {
    return this.required ? { required: { allowFalse: false } } : ''
  }

  labelValue(label: string) {
    return stripTags(label)
  }

  descriptionValue(description: string) {
    return stripTags(description)
  }
}
