




















































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import AbstractField from '@Forms/components/fields/AbstractField.vue'
import { stripTags } from '@Forms/helpers/stripTags.js'

@Component
export default class TextField extends mixins(AbstractField) {
  @Prop() autoComplete?: Boolean

  labelValue(label: string) {
    return stripTags(label)
  }

  descriptionValue(description: string) {
    return stripTags(description)
  }

  get validationRules() {
    if (this.fieldType === 'url') {
      return `${this.compiledRules}|url`
    }

    return this.compiledRules
  }
}
