















































import { Component, mixins } from 'nuxt-property-decorator'
import BasePage from '@Marketing/mixins/BasePage.vue'
import PageContainer from '@Marketing/components/containers/PageContainer.vue'
import FlexibleLayoutContainer from '@Marketing/components/containers/FlexibleLayoutContainer.vue'
import InPageNav from '@UI/components/page-nav/InPageNav.vue'
import { getTaxonomyCollection } from '@Marketing/helpers/cms'
import { CmsLink } from '@Core/@types/skyway'

/**
 * Default layout for any page without a more specific template
 */
@Component({
  components: {
    PageContainer,
    FlexibleLayoutContainer,
    InPageNav,
  },
})
export default class Page extends mixins<BasePage>(BasePage) {
  /**
   * Will be determined by page type, just show component
   * if they exist for now
   */
  get showAnchors(): boolean {
    return Boolean(this.pageAnchors && this.pageAnchors.length > 0)
  }

  get anchors(): CmsLink[] {
    return this.showAnchors
      ? this.pageAnchors.map((a) => {
          return {
            title: a.data.title,
            url: `#${encodeURIComponent(a.data.title).toLowerCase()}`,
          }
        })
      : []
  }

  get headerComponentOverrides() {
    return {
      FeaturedPostCollection: 'FeaturedPostsHeroAlt',
      TextBanner: 'Banner',
    }
  }
}
