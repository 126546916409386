

































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Link } from '@Core/@types/types'

/**
 * MobilePageNav
 * @param anchors - the list of links to display
 * @param position - the position of the nav
 * @param anchorLinks - whether the links are anchor links or external urls
 */
@Component
export default class MobilePageNav extends Vue {
  @Prop({ type: Array, required: true }) anchors!: {
    title: string
    href: string
  }[]

  @Prop({
    type: String,
    default: 'top',
    validator(value: string) {
      return ['top', 'side'].includes(value)
    },
    required: true,
  })
  position!: string

  // Update the current active link showing along with the step number
  get activeLink() {
    // use existing data
    if (this.anchors.length > 0) {
      return `${this.anchors[0].title}`
    }
    // or use the route path
    if (
      this.anchors.filter((a: Link) => a.href === this.$route.path).length > 0
    ) {
      return `${
        this.anchors.filter((a: Link) => a.href === this.$route.path)[0].href
      }. ${
        this.anchors.filter((a: Link) => a.href === this.$route.path)[0].title
      }`
    }
    // default
    return ''
  }

  protected viewLinks: boolean = false

  toggleViewLinks(): void {
    this.viewLinks = !this.viewLinks
  }

  isAnchorLink(href: string) {
    return href.includes('#')
  }

  updateActiveLink(link: any): void {
    this.viewLinks = false

    if (this.isAnchorLink(link.href)) {
      const id = link.href.split('#')[1]
      const section = document.getElementById(id)
      const header = document.getElementById('header')
      window.scrollTo({
        top:
          section?.getBoundingClientRect().top +
          header?.getBoundingClientRect().bottom,
      })
    }
  }

  get selectableLinks(): Link[] {
    return this.anchors.filter((anchor) => anchor.title !== this.activeLink)
  }
}
