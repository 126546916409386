








































import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import CheckboxField from '@UI/components/forms/fields/CheckboxField.vue'
import { stripTags } from '@Forms/helpers/stripTags.js'
//import CheckboxField from '@Forms/components/fields/CheckboxField.vue'

@Component({
  components: {
    ValidationProvider,
    CheckboxField,
  },
})
export default class CheckboxGroupField extends Vue {
  @Prop({ type: Array, required: true }) checkboxes!: Array<{
    label: string
    sublabel: string
    disabled: Boolean
  }>
  @Prop({ type: String }) label?: string
  @Prop({ type: String }) description?: string
  @Prop({ type: String }) compiledRules?: string
  @Prop({ type: String }) sublabel?: string
  @Prop({ type: Boolean, required: false, default: false }) required?: Boolean
  labelUppercase?: Boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  inline?: Boolean

  @VModel({ type: Array }) selection!: string[]

  //protected selection = []

  labelValue(label: string) {
    return stripTags(label)
  }

  descriptionValue(description: string) {
    return stripTags(description)
  }

  get isRequired() {
    //console.log(this.selection)
    return this.required ? { required: { allowFalse: false } } : ''
  }
}
