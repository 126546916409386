
































import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator'
import SigninEmail from '@Auth/components/forms/sign-in/SigninEmail.vue'
import SigninPassword from '@Auth/components/forms/sign-in/SignInPassword.vue'
import SigninActivateAccount, {
  CustomerToActivate,
} from '@Auth/components/forms/sign-in/SigninActivateAccount.vue'
import { config } from '@Core/config/skyway'
import {
  CustomerTransformer,
  PreferencesTransformer,
} from '@Account/api/transformers/customer'
import {
  Customer,
  ContactPreferenceCategory,
  ContactPreferenceType,
  CustomerInput,
} from '@Core/@types/skyway'

const customer = namespace('customer')

@Component({
  components: {
    SigninEmail,
    SigninPassword,
    SigninActivateAccount,
  },
})
export default class SigninContainer extends Vue {
  @Prop({ type: String, default: config.URLS.register })
  redirectOnEmail!: string

  public emailValid: boolean = false
  public emailExists: boolean = false
  public loading: boolean = false
  public email: string | null = ''
  public password?: string = undefined
  public submitted: boolean = false
  public showAddPassword: boolean = false

  @customer.Action
  protected init

  @customer.Action
  activateAccount

  @customer.State
  known_users

  @customer.State
  protected customer!: Customer

  @customer.Action
  protected updateCustomer!: (input: CustomerInput) => Promise<Customer>

  @customer.Action
  protected updateContactPreferences!: (
    input: ContactPreferenceType[]
  ) => Promise<Customer>

  @customer.State
  temporary_email

  @customer.Action
  protected getContactPreferenceCategories!: () => Promise<
    ContactPreferenceCategory[]
  >

  @customer.Getter
  protected contactPreferenceTypes!: ContactPreferenceType[]

  get name(): string | void {
    try {
      const known = this.known_users
      const arr: Array<string> = Array.from(known)
      const current = arr.find((user: String) =>
        user[0].includes(this.temporary_email)
      )

      const name = current ? current[0].split('|')[0] : undefined

      if (name !== undefined) {
        return `${name[0].toUpperCase()}${name.slice(1)}`
      }

      return name
    } catch (err) {}
  }

  get emailPreferenceTypes() {
    return this.contactPreferenceTypes.filter((type) => type.name === 'Email')
  }

  onEmailSubmitted(email): void {
    this.email = email
    this.onSubmitEmail()
  }

  onEditEmail(): void {
    this.email = null
    this.submitted = false
    this.emailExists = false
    this.showAddPassword = false
  }

  async onSubmitEmail(): Promise<void> {
    this.loading = true

    if (this.email) {
      const result = await this.checkEmailExists(this.email)
      this.$emit('email-exists', result)

      this.$store.commit('customer/SET_TEMPORARY_EMAIL', this.email)

      if (result === 1) {
        this.emailExists = true
        this.submitted = true
        this.loading = false
      } else if (result === 2) {
        this.emailExists = false
        this.submitted = false
        this.loading = false
        this.showAddPassword = true
      } else if (this.$route.path !== this.redirectOnEmail) {
        this.$router.push(this.redirectOnEmail)
      }
    }
  }

  /**
   * @return  number  0 = email does not exist 1 = login exists, 2 = email exists, but not tied to a login
   */
  async checkEmailExists(email: string): Promise<number> {
    this.$emit('checking-email', true)

    const checkEmailExists = await this.$store.dispatch(
      'customer/checkEmailExists',
      email
    )

    if (checkEmailExists) {
      return checkEmailExists
    } else {
      return 0
    }
  }

  async onActivateAccount(customer: CustomerToActivate) {
    try {
      const { customer_ref, last_name, credentials, first_name, preferences } =
        customer

      this.loading = true
      const result = await this.activateAccount({
        customer_ref,
        last_name,
        credentials,
      })

      if (result?.logged_in) {
        await this.$store.dispatch('customer/processLogin', result)
        await this.$nextTick()
        if (this.customer) {
          const payload: CustomerInput = CustomerTransformer(
            Object.assign({}, this.customer, {
              first_name,
            })
          )
          await this.updateCustomer(payload)
          await this.updateContactPreferences(
            PreferencesTransformer(preferences)
          )

          this.redirectOnAuth()
        }
      } else {
        this.$eventBus.notifyFailure(
          'Sorry, there was a problem activating your account. Please try again.'
        )
        this.loading = false
      }
    } catch (err_) {
      this.$eventBus.notifyFailure(
        'Sorry, there was a problem activating your account. Please try again.'
      )
      this.loading = false
      console.log(err_)
    }
  }

  async redirectOnAuth() {
    const redirect = await this.$store.dispatch('session/getRedirect')
    await this.$store.dispatch(
      'session/setRedirect',
      this.$config.get('URLS').account
    )
    this.$router.replace(redirect)
  }

  async onPasswordSubmitted(password): Promise<any> {
    this.loading = true

    this.password = password

    if (this.email && this.password) {
      try {
        // if email parsed to int is the same as the input
        // then we have a customer number, rather than an email
        let token
        if (`${parseInt(this.email)}` == this.email) {
          token = await this.$store.dispatch('customer/idLogin', {
            customer_ref: this.email,
            password: this.password,
          })
        } else {
          token = await this.$store.dispatch('customer/login', {
            username: this.email,
            password: this.password,
          })
        }

        if (token && token.logged_in) {
          const result = await this.$store.dispatch(
            'customer/processLogin',
            token
          )

          if (result) {
            this.redirectOnAuth()
          } else {
            this.showError()
          }
        } else {
          this.showError()
        }
      } catch (err_) {
        console.log(err_)
        this.showError()
      }
    }
  }

  showError() {
    this.loading = false
    this.$eventBus.notifyFailure(
      'The email/password combination is not correct, please check and try again'
    )
  }

  onSocialLogin(loading: boolean): void {
    this.loading = loading
  }

  async mounted() {
    try {
      if (this.contactPreferenceTypes.length < 1) {
        await this.getContactPreferenceCategories()
      }
    } catch (err_) {
      this.$logger.captureException(err_, {
        tags: { recaptcha: `[Init failed]` },
      })
    }

    this.$eventBus.on('social-login:init', () => this.onSocialLogin(true))
    this.$eventBus.on('social-login:fail', () => this.onSocialLogin(false))
    this.init()
  }

  beforeDestroy() {
    this.$eventBus.off('social-login:init', () => this.onSocialLogin(true))
    this.$eventBus.off('social-login:fail', () => this.onSocialLogin(false))
  }
}
