export default class Model {
  constructor(values: any) {
    for (let key in values) {
      if (typeof values[key] === 'object') {
        for (let inner in values[key]) {
          this[key] = { ...this[key], [inner]: values[key][inner] }
          console.log({ [key]: this[key] })
        }
      } else {
        this[key] = values[key]
      }
    }
  }
}
