







































































import { Component, Vue, VModel, Prop } from 'nuxt-property-decorator'

@Component({})
export default class RegisterNewAccountCredentials extends Vue {
  @Prop({ type: Boolean, default: false }) emailInputAllowed?: boolean
  @VModel({ type: Object }) localModel

  protected inputPassword: string = ''
  protected showErrors: Boolean = false

  increment = 0

  onClickChangeEmail(email: string): void {
    this.$emit('auth:change-email', email)
  }

  onEditEmail(): void {
    this.$emit('auth:edit-email')
  }

  async onClickContinue(): Promise<any> {
    try {
      const emailValid = this.$refs.email && (await this.$refs.email.validate())

      if (!emailValid) {
        this.onError()
        return
      }

      const passValid = this.$refs.pass && (await this.$refs.pass.validate())

      if (passValid) {
        this.$emit('auth:submit-credentials')
      } else {
        this.onError()
        return
      }
    } catch (err_) {
      this.onError()
    }
  }

  onError(err_ = ''): void {
    this.showErrors = true
    this.increment++
    this.$eventBus.notifyFailure(
      'Please check that all fields are valid and try again'
    )
  }
}
