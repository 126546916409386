
































import { Component, VModel, Vue, Watch, Prop } from 'nuxt-property-decorator'
import CheckboxField from '@UI/components/forms/fields/CheckboxField.vue'

@Component({
  components: { CheckboxField },
})
export default class PostcodeOnlyForm extends Vue {
  @VModel({ type: String }) postcode!: string
  @Prop({ type: Boolean, default: false }) isWorkshop!: boolean

  protected noUKAddress: boolean = false
  protected isDisabled: boolean = false

  get postCodeRules(): string {
    return !this.noUKAddress ? 'required' : ''
  }

  @Watch('noUKAddress')
  onUpdateNoAddress(newVal: boolean): void {
    if (newVal && newVal === true) {
      this.postcode = ''
      this.isDisabled = true
    } else {
      this.isDisabled = false
    }
  }

  get noFixedAddressLabel() {
    return this.isWorkshop
      ? "They don't have a fixed UK address"
      : "I don't have a fixed UK address"
  }
}
