



























import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
import CheckboxField from '@UI/components/forms/fields/CheckboxField.vue'
import { ContactPreferenceType } from '@Core/@types/skyway'

@Component({
  components: {
    CheckboxField,
  },
})
export default class RegisterFormPreferences extends Vue {
  @Prop({ type: Array, required: true })
  marketingPreferences!: ContactPreferenceType

  @VModel() preferences

  getLabel(type: ContactPreferenceType): string {
    return type.type_ref === '1' ? 'General mailing list' : 'Youth mailing list'
  }

  getImage(pref: ContactPreferenceType): string | false {
    return pref.images && pref.images[0]
      ? `${this.$config.get('ASSET_PATH')}/${pref.images[0].image}`
      : false
  }
}
