






import { Component } from 'vue-property-decorator'
import AbstractField from '@Forms/components/fields/AbstractField.vue'

@Component
export default class ParagraphField extends AbstractField {}
