// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.forms-single__container h1:not(.form__title),.forms-single__container h2:not(.form__title),.forms-single__container h3:not(.form__title),.forms-single__container h4:not(.form__title),.forms-single__container h5:not(.form__title),.forms-single__container h6:not(.form__title){font-family:\"Work Sans\",sans-serif;font-size:1.0625rem;line-height:1.3125rem;text-transform:uppercase;font-weight:700;margin-bottom:.5rem;color:#f7f6f3}@media only screen and (min-width:768px){.forms-single__container h1:not(.form__title),.forms-single__container h2:not(.form__title),.forms-single__container h3:not(.form__title),.forms-single__container h4:not(.form__title),.forms-single__container h5:not(.form__title),.forms-single__container h6:not(.form__title){font-size:1.25rem;line-height:1.5rem}}.forms-single__container ol,.forms-single__container ul{margin-top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
