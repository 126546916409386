
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class AbstractField extends Vue {
  @Prop({ type: String, required: true }) label!: string
  @Prop({ type: String }) description!: string
  @Prop({ type: String | Number }) value!: string
  @Prop({ type: String }) id!: string
  @Prop({ type: String }) vid?: string
  @Prop({ type: String }) placeholder!: string
  @Prop({ type: String }) rules?: string
  @Prop({ type: String }) min?: string
  @Prop({ type: String, default: 'eager' }) mode?: string
  @Prop({ type: String }) input_class?: string
  @Prop({ type: String, default: 'text' }) fieldType!: string
  @Prop({ type: String | Boolean, default: 'false' }) required?:
    | boolean
    | string
  @Prop({ type: String, default: '' }) autocomplete?: string
  @Prop({ type: Boolean, default: false }) readonly?: boolean
  @Prop({ type: Boolean, default: false }) hideLabel!: boolean

  get requiredBoolean() {
    return !(this.required == '' || this.required == 'false' || !this.required)
  }

  get compiledRules(): string {
    if (this.rules !== undefined && this.rules != '' && this.requiredBoolean) {
      return `${this.rules}|required`
    } else if (this.rules == '' && this.requiredBoolean) {
      return `required`
    } else {
      return this.rules || ''
    }
  }

  get compiledLabel() {
    // return this.requiredBoolean || this.compiledRules.includes('required')
    //   ? `${this.label} <span style='color:red'>*</span>`
    //   : this.label

    return this.label
  }

  get localValue() {
    return this.value
  }

  set localValue(localValue) {
    this.$emit('input', localValue)
  }
}
