
















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import CheckMarked from '~/static/images/svgs/checkbox-marked.svg?inline'
import CheckEmpty from '~/static/images/svgs/checkbox-blank-outline.svg?inline'

@Component({
  components: {
    ValidationProvider,
    CheckMarked,
    CheckEmpty,
  },
})
export default class CheckboxField extends Vue {
  @Prop({ type: String, required: true }) label!: string
  @Prop({ type: String }) id!: string
  @Prop({ required: true, default: false }) value!: any
  @Prop({ type: Boolean, required: false, default: false }) disabled?: Boolean
  @Prop({ type: Boolean, required: false, default: false }) required?: Boolean

  get isChecked(): Boolean {
    return this.value
  }

  set isChecked(newVal) {
    if (this.disabled || typeof newVal !== 'boolean') {
      return
    }

    this.$emit('input', newVal)
  }

  get isRequired() {
    return this.required ? { required: { allowFalse: false } } : ''
  }

  get compiledLabel() {
    return this.isRequired ? `${this.label}` : this.label
  }

  mounted() {
    this.isChecked = this.value
  }

  toggleSelection(): void {
    this.isChecked = !this.isChecked
  }
}
