var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContainer',{staticClass:"page",class:_vm.slug ? ("page--" + _vm.theme + " page--" + _vm.slug) : ("page--" + _vm.theme),attrs:{"show-default-banner":false},scopedSlots:_vm._u([{key:"head",fn:function(ref){
var headComponents = ref.headComponents;
return [_c('FlexibleLayoutContainer',{staticClass:"layout--header",attrs:{"cms-components":headComponents,"page-template":_vm.pageTemplate,"page":_vm.page,"overrides":_vm.headerComponentOverrides}})]}},{key:"anchors",fn:function(){return [(_vm.showAnchors)?_c('InPageNav',{attrs:{"anchor-links":_vm.anchors}}):_vm._e()]},proxy:true},{key:"fixed",fn:function(ref){
var fixedComponents = ref.fixedComponents;
return [_c('FlexibleLayoutContainer',{staticClass:"layout--main",attrs:{"cms-components":fixedComponents,"layout-settings":_vm.layoutSettings,"page-template":_vm.pageTemplate,"page":_vm.page}})]}},{key:"flexible",fn:function(ref){
var mainComponents = ref.mainComponents;
return [_c('FlexibleLayoutContainer',{staticClass:"layout--main",attrs:{"cms-components":mainComponents,"layout-settings":_vm.layoutSettings,"page-template":_vm.pageTemplate,"page":_vm.page}})]}},{key:"footer",fn:function(ref){
var footerComponents = ref.footerComponents;
return [_c('FlexibleLayoutContainer',{staticClass:"layout--footer",attrs:{"layout-settings":_vm.layoutSettings,"cms-components":footerComponents,"page-template":_vm.pageTemplate,"page":_vm.page}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }